import React from 'react';
import { splitPrice } from "./split-price";

export interface PriceProps {
  standard: number;
  current: number | false | undefined;
}

export const Price:React.FC<PriceProps> = ({ standard, current }) => {
  const { dollars: standardDollars, cents: standardCents } = splitPrice(
    standard
  );
  const { dollars: currentDollars, cents: currentCents } = splitPrice(current);

  return (
    <div className="product-price">
      {current && (
        <div className="current-price">
          <span className="dollars">${currentDollars}</span>
          <sup>{currentCents}</sup>
        </div>
      )}
      <div className={`standard-price ${current && "crossed-out"}`}>
        <span className="standard-dollars">${standardDollars}</span>
        <sup>{standardCents}</sup>
        {current && <div className="strike"></div>}
      </div>
    </div>
  );
};

export default Price;
