import { WebServiceClient } from '@maxmind/geoip2-node';
import msdyn365Commerce, { IGeoLocation, IGeoLookupProvider, IRequestContext, ISecretValue } from '@msdyn365-commerce/core';

// tslint:disable-next-line:completed-docs
class MsDyn365GeoLookupConnector implements IGeoLookupProvider {

  public initialize(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public async getGeoInformation(ip: string, ctx: IRequestContext): Promise<IGeoLocation> {
    const secretAccountId: ISecretValue | undefined = await msdyn365Commerce.secretManager?.getSecretValue('mm-account-id', ctx.apiSettings.baseUrl);
    const secretLicenseKey: ISecretValue | undefined = await msdyn365Commerce.secretManager?.getSecretValue('mm-license-key', ctx.apiSettings.baseUrl);
    const client = new WebServiceClient(secretAccountId?.value || '', secretLicenseKey?.value || '');
    const maxmindData = await client.city(ip);
    const state = maxmindData.subdivisions && maxmindData.subdivisions.length > 0 ? maxmindData.subdivisions[0].isoCode : '';
    // Metro code property added for targetting functionlaity (Murali)
    const metroCode = maxmindData.location && maxmindData.location.metroCode && maxmindData.location.metroCode.toString() || '';
    const geoLocation: IGeoLocation = {
      country: maxmindData.country?.isoCode,
      city: maxmindData.city?.names.en,
      region: state,
      zipCode: maxmindData.postal?.code,
      metroCode
    };
    return Promise.resolve(geoLocation);
  }
}

const connector = new MsDyn365GeoLookupConnector();
export default connector;