import React from 'react';
import { Price } from '../price/price.component';
export interface SelectableCardProps {
    img: React.ReactNode;
    productName: string;
    ratings?: React.ReactNode;
    standardPrice?: false | number;
    currentPrice?: false | number | undefined;
    eyebrowText?: string;
    isActive?: boolean
}

const SelectableCard:React.FC<SelectableCardProps> = ({ img, productName, ratings, standardPrice, currentPrice, eyebrowText, isActive = true }) => {
    return (
        <div className={`selectable-card ${!isActive && 'selectable-card__inactive'}`}>
            <div className='selectable-card__image'>{img}</div>
            <div className='selectable-card__content'>
                <div className='selectable-card__eyebrow'>{eyebrowText}</div>
                <div className='selectable-card__product-name'>{productName}</div>
                {ratings && (
                    <div className='selectable-card__rating'>
                        {ratings}
                    </div>
                )}
                {standardPrice && <Price standard={standardPrice} current={currentPrice} />}
            </div>
        </div>
    );
};

export default SelectableCard;