export const splitPrice = (price: number | boolean | undefined) => {
  if (price === 0) {
    return { dollars: "0", cents: "" };
  }
  const formattedPrice = price?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });

  const priceArr = formattedPrice?.match(/(\d|,)+/g);

  const dollars = priceArr?.[0] || "0";
  const cents = priceArr?.[1] || "00";

  return { dollars, cents };
};
